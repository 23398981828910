.App {
  text-align: center;
  background-color: #f2f2f2;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
h5 {
  margin: 0;
}
p {
  font-size: 1rem;
  line-height: 1.4rem;
}
.GeneratorContainer {
  display: flex;
  gap: 30px;
}

.Form,
.Preview {
  text-align: left;
  border-radius: 5px;
  min-width: 400px;
  background-color: #fff;
  padding: 25px;
}

.FormInput {
  display: flex;
  flex-direction: column;
  /* padding-left: 15px; */
  margin-top: 14px;
}
.FormInput label {
  font-size: 11px;
}
.FormInput input {
  width: 80%;
  height: 1.6rem;
  padding: 3px;
  border: none;
  border-bottom: 1px solid black;
  background-color: transparent;
  margin-top: 4px;
}
.FormInput input:focus {
  outline: transparent;
  border-bottom: 2px solid #5999c6;
}
.FormInput input[type="checkbox"] {
  width: 20px;
  text-align: left;
}

.GenerateButton {
  background-color: #5999c6;
  color: white;
  width: 100%;
  min-width: 200px;
  font-size: large;
  border: 0;
  padding: 10px;
  margin: 10px 0;
  cursor: pointer;
}

.GenerateButton:hover {
  background-color: #213781;
}
textarea {
  width: 100%;
  height: 190px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;
  resize: none;
}

.mt-4 {
  margin-top: 4rem;
}
